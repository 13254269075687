import { EHeaderMenuItems, EStatisticItemVariants, type IAppsConfig } from '~/config/apps/index.types';

export const useProjectSettings = () =>
  <IAppsConfig>{
    project: 'cs-global',
    game: 'cs',
    theme: 'gray',
    defaultCurrency: 'USD',
    auth: {
      isCheckboxesSelectedByDefault: false,
      variants: ['steam', 'google'],
    },
    cases: {
      showButtons: false,
    },
    contacts: {
      adMail: 'media_ts@tastyteam.cc',
      supportMail: 'support@tastyteam.cc',
      partnerMail: 'partner@tastyteam.cc',
      hideVk: true,
    },
    faq: {
      type3: {
        categoryId: 1,
        questionId: 1,
      },
    },
    footer: {
      copyright: 'CS Land',
      paymentMethods: ['visa', 'mastercard', 'bitcoin', 'tether', 'ton'],
      hideSocials: true,
    },
    legalData: {
      beneficiary: 'Big Corgi Development OÜ',
      registrationNumber: 16252850,
      address: 'Harju maakond, Tallinn, Kesklinna linnaosa, Masina tn 22, 10113',
    },
    isBattlesDisabled: true,
    isChallengesDisabled: true,
    isProvablyFairDisabled: false,
    isUpgradesDisabled: true,
    isTechiesDisabled: false,
    hideBattlesBanner: true,
    hideSecretShop: true,
    hideFreeCasesBanner: false,
    hideHeaderMenuItems: [
      EHeaderMenuItems.BONUS_PAGE,
      EHeaderMenuItems.SECRETSHOP,
      EHeaderMenuItems.UPGRADES,
      EHeaderMenuItems.CONTRACT,
      EHeaderMenuItems.TECHIES,
    ],
    hideProjectSwitcher: true,
    howItWorks: {
      hideStep6: true,
    },
    supportKey: 'sePmTTSmEQ',
    seo: {
      favicon: '/img/favicon/cs.ico',
      title: 'CS Land - CS 2 cases',
      description:
        'CS Land — largest CS 2 cases site. Buy CS 2 cases and get high quality skins on your favorite weapon! Withdraw your winnings to Steam within 1 minute.',
    },
    statistics: {
      hideElements: [EStatisticItemVariants.ARCANES, EStatisticItemVariants.UPGRADES, EStatisticItemVariants.BATTLES],
    },
    techWork: {
      logoClass: 'logo--dota',
    },
    payment: {
      project: 'CS Land',
    },
    redirects: {
      newCases: '/new-cases',
    },
    subscriptions: {
      variants: ['email', 'steam'],
      show: false,
    },
    drop: {
      hideShare: true,
    },
  };
